//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ProfilePic from '~/components/Profile/ProfilePic.vue';
import { ROUTES } from '~/constants/pageRoutes';
import analyticEvents from '~/services/analyticEvents';

// importing icons
import { chevronGrayBold } from '~/static/icons/index';

export default {
    name: 'NavbarPwa',
    components: {
        ProfilePic
    },
    data() {
        return {
            // ICONS
            chevronGrayBold,

            // CONSTANTS
            ROUTES,
            analyticEvents
        };
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            isAuthenticated: 'auth/isAuthenticated',
            isPremiumUser: 'auth/isPremiumUser'
        })
    },

    methods: {
        goToProfile() {
            if (this.$device.isMobile) {
                this.$router.push(ROUTES.PROFILE);
            }
        },
        openSideBar() {
            this.$store.commit('UIElementsState/setSideBarStatus', true);
        }
    }
};
