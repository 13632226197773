//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ProfilePic from '~/components/Profile/ProfilePic.vue';
import { ROUTES } from '~/constants/pageRoutes';
import analyticEvents from '~/services/analyticEvents';

// importing icons
import { chevronGrayBold } from '~/static/icons/index';

export default {
    name: 'NavbarTablet',
    components: {
        ProfilePic
    },
    data() {
        return {
            // ICONS
            chevronGrayBold,

            // CONSTANTS
            ROUTES,
            analyticEvents
        };
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            isAuthenticated: 'auth/isAuthenticated',
            isPremiumUser: 'auth/isPremiumUser',
            isSidebarOpen: 'UIElementsState/getSideBarStatus'
        })
    },

    methods: {
        goToProfile() {
            this.$router.push(ROUTES.PROFILE);
        },
        toggleSideBar() {
            this.$store.commit('UIElementsState/setSideBarStatus', !this.isSidebarOpen);
        }
    }
};
